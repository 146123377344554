<template>
    <div class="job__filter">
        <a href="javascript:void(0)" @click="toggleJobFilter()" class="filter__toggler"><i class="eicon e-menu"></i></a>

        <form action="" class="form-filter" :class="{show: showJobFilter}" @submit.prevent="searchJobPost">
            <job-type-switcher></job-type-switcher>

            <div class="search-bar">
                <input type="text" ref="search" v-model="form.search" @keyup="searchJobPost" :placeholder="$t(`Search Job Name`)+` . . .`" autofocus/>
            </div>

            <template v-if="showActiveFilter">
                <label class="checkbox"  @change="filterChanged">
                    <input v-model="form.active" type="checkbox">
                    <span>{{$t(`Active`)}}</span>
                </label>
                <label class="checkbox mr-auto">
                    <input v-model="form.expired" type="checkbox" @change="filterChanged">
                    <span> {{$t(`Expired`)}}</span>
                </label>
            </template>

            <router-link v-if="canManagementJob" :to="{name : 'job.create'}" class="button primary-button text-capitalize">
                <span class="icon"><i class="eicon e-plus"></i></span>
                <span class="text-capitalize">{{ $t(`Create a job post`)}}</span>
            </router-link>
        </form>
    </div>
</template>
<script>
    import _ from 'lodash';
    import {canManagementJob} from "../../config/permission";
    const JobTypeSwitcher = () =>  import( "../dropdown/JobTypeSwitcher.vue");

    export default {
        data () {
            return {
                form: {
                    search: '',
                    active: true,
                    expired: true
                },
                showJobFilter: false
            }
        },

        components : {
            JobTypeSwitcher
        },

        computed: {
            showActiveFilter() {
              return this.$route.name === 'jobs.published'
            },
            canManagementJob() {
                return canManagementJob();
            },
        },

        methods : {
            toggleJobFilter() {
                this.showJobFilter = !this.showJobFilter;
            },

            searchJobPost : _.debounce(function() {
                this.$emit('searched', this.form)
            }, 1000),

            filterChanged() {
                this.$emit('searched', this.form)
            }
        }
    }
</script>

<style scoped>

    .published-jobs .form-filter {
        flex-wrap: wrap;
        gap: 20px;
    }

    .published-jobs .form-filter .checkbox {
        color: #597dfc;
    }

    .form-filter>*:not(:last-child) {
        margin-right: 0;
    }

    .filter__toggler {
        display: none;
    }
    @media all and (max-width: 767px) {
        .filter__toggler {
            display: inline-block;
            height: 35px;
            width: 35px;
            background: #fff;
            border-radius: 5px;
            line-height: 35px;
            text-align: center;
        }
        .job__filter {
            position: relative;
            margin-bottom: 20px;
        }
        .form-filter>*:not(:last-child) {
            margin-bottom: 20px;
        }
        .job__filter .form-filter .search-bar {
            width: 100%;
        }
        .job__filter .form-filter {
            display: none !important;
            background: #fff;
            border-radius: 10px;
            padding: 20px;
            position: absolute;
            top: calc(100% + 10px);
            left: 0;
            width: 100%;
            z-index: 99;
            box-shadow: 3px 3px 10px 3px rgba(0, 0, 0, .1);
        }
        .job__filter .form-filter input {
            background: #f5f7fd !important;
            border: 1px solid rgba(89, 125, 252, .3) !important;
        }
        .job__filter .form-filter .checkbox {
            margin-right: 20px;
        }
        .job__filter .form-filter.show {
            display: block !important;
        }
    }

</style>
